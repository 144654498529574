body > .container {
    margin-top: 20px;
}

h3 {
    margin-bottom: 1.5rem;
}
.form-group {
    margin-bottom: 1.3rem;
}

.form-group.required label:after {
  content:" *";
  color:red;
}